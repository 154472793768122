<nav #navbar class="navbar navbar-expand-lg navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-info btn-fab btn-round"
          style="background-color: #31304d; margin-bottom: 10px"
        >
          <i
            class="material-icons text_align-center visible-on-sidebar-regular"
            style="font-size: xx-large"
            >arrow_left</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            style="font-size: xx-large"
            >arrow_right</i
          >
        </button>
      </div>
      <!-- <a class="navbar-brand"> {{ getTitle() }}</a> -->
      <!-- <p>{{ getTitle() }}</p> -->
    </div>
    <button
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <form
        class="navbar-form"
        [formGroup]="navbarForm"
        style="margin-top: -8px"
      >
        <div class="input-group no-border"></div>

        <!-- <label for="" style="margin-bottom: 1px">Select Site</label><br />
        <select
          class="custom-select"
          formControlName="siteIdValue"
          (change)="onChangeSiteId(navbarForm.controls['siteIdValue'].value)"
        >
          <option value="" disabled selected hidden>----Select Site----</option>
          <option *ngFor="let id of siteId" [value]="id.siteCode">
            {{ id.siteCode }}
          </option>
        </select> -->

        <select
          class="classic"
          formControlName="siteIdValue"
          (change)="onChangeSiteId(navbarForm.controls['siteIdValue'].value)"
        >
          <option value="" disabled selected hidden>----Select Site----</option>
          <option *ngFor="let id of siteId" [value]="id.siteCode">
            {{ id.siteCode }}
          </option>
        </select>
      </form>

      <!-- <button mat-raised-button class="btn btn-primary" (click)="calDistance()">
        Calculate Distance
      </button> -->
      <!-- &nbsp;
      <button
        mat-raised-button
        class="btn btn-primary"
        (click)="markerSetting()"
      >
        Marker Setting
      </button> -->

      <!-- &nbsp;
      <button
        style="margin-top: 6px"
        mat-mini-fab
        (click)="onRoted(10)"
        matTooltip="Rotate Right"
        *ngIf="showCancelBtn"
      >
        <mat-icon>rotate_right</mat-icon>
      </button> -->
      <!-- &nbsp;
      <button
        style="margin-top: 6px"
        mat-mini-fab
        (click)="onRoted(-10)"
        matTooltip="Rotate Left"
        *ngIf="showCancelBtn"
      >
        <mat-icon>rotate_left</mat-icon>
      </button> -->
      <!-- &nbsp;
      <button style="margin-top: 6px" mat-mini-fab (click)="onEdit($event)">
        <mat-icon *ngIf="editButtonName == 'Edit'" matTooltip="Edit"
          >edit</mat-icon
        >
        <mat-icon *ngIf="editButtonName == 'Save'" matTooltip="Save"
          >save</mat-icon
        >
      </button> -->
      <!-- &nbsp;
      <button
        style="margin-top: 6px"
        *ngIf="showSliderDeleteBtn"
        mat-mini-fab
        (click)="showAlert($event)"
        matTooltip="Delete Selected"
      >
        <mat-icon>delete</mat-icon>
      </button> -->

      <!-- &nbsp;
      <button
        style="margin-top: 6px"
        *ngIf="showCancelBtn"
        mat-mini-fab
        (click)="onCancel($event)"
        matTooltip="Cancel"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      &nbsp; -->

      <!-- <button
        style="margin-top: 6px"
        *ngIf="showCancelBtn"
        mat-mini-fab
        (click)="onEditLabel($event)"
        matTooltip="Edit Label"
      >
        <mat-icon>edit_note</mat-icon>
      </button>
      &nbsp; -->
      <!-- <button
        style="margin-top: 6px"
        mat-mini-fab
        matTooltip="Download CSV File"
        color="primary"
        (click)="downloadCsv($event)"
      >
        <img
          [src]="baseUrlImg + 'assets/img/csv3.png'"
          class="downloadFileLogo"
          loading="lazy"
        />
      </button> -->
      <!-- &nbsp; -->
      <button
        mat-mini-fab
        style="margin-bottom: 10px; margin-left: 2%"
        (click)="onLogOut()"
        matTooltip="Logout"
        color="accent"
      >
        <mat-icon>logout</mat-icon>
      </button>
      <!-- <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a class="dropdown-item" href="#"
              >Mike John responded to your email</a
            >
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
