import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CsvService } from "src/app/services/Csvv.service";

interface Option {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-distancecalculator-dialog",
  templateUrl: "./distancecalculator-dialog.component.html",
  styleUrls: ["./distancecalculator-dialog.component.css"],
})
export class DistancecalculatorDialogComponent {
  selectedColor: string;
  selectedPattern: string;
  fromAddress: string;
  toAddress: string;
  distance: number;
  constructor(
    public dialogRef: MatDialogRef<DistancecalculatorDialogComponent>,
    private csvService: CsvService
  ) {}

  colors: Option[] = [
    { value: "red", viewValue: "Red" },
    { value: "green", viewValue: "Green" },
    { value: "blue", viewValue: "Blue" },
    { value: "yellow", viewValue: "Yellow" },
  ];

  patterns: Option[] = [
    { value: "bubble", viewValue: "Bubble" },
    { value: "pin", viewValue: "Pin" },
    { value: "hospital", viewValue: "Hospital" },
    { value: "school", viewValue: "School" },
  ];

  onCancel(): void {
    this.dialogRef.close();
  }

  calculateDistance() {
    // Here you would implement the logic to calculate the distance between fromAddress and toAddress
    // This is just a placeholder example
    // Assuming you have a function to calculate distance called calculateDistance
    // this.distance = this.calculateDistance(this.fromAddress, this.toAddress);
    let address = {
      fromAddress: this.fromAddress,
      toAddress: this.toAddress,
    };
    this.csvService.calculateDistance.next(address);
    this.dialogRef.close();
  }
}
