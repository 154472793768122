<div class="wrapper">
  <div
    class="sidebar"
    data-color="white"
    data-background-color="red"
    data-image="./assets/img/sidebar-1.jpg"
  >
    <app-sidebar-cmp></app-sidebar-cmp>
    <!--  style="background-image: url(assets/img/sidebar-1.jpg)" -->
    <!-- <div class="sidebar-background"></div> -->
  </div>
  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <router-outlet></router-outlet>
    <div *ngIf="!isMap()">
      <!-- <app-footer-cmp></app-footer-cmp> -->
    </div>
  </div>
  <app-fixedplugin></app-fixedplugin>
</div>
