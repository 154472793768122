// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // baseUrl: "http://3.234.160.69/rfid/",
  // baseUrl: "http://52.55.49.214/rfid/",
  baseUrl: "https://rfidngpsinventory.com/rfid/",
  baseUrlImage: "http://rfidngpsinventory.com/",
  // baseUrl: "http://localhost:8081/atlyms/",
  // baseUrl:
  //   "http://lb-inventory-68949982.us-east-1.elb.amazonaws.com/rfid/atlyms/",
};
