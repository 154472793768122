<!-- setting-dialog.component.html -->
<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
         Color
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>Select color</mat-label>
        <select matNativeControl  [(ngModel)]="selectedColor">
          <option *ngFor="let color of colors" [value]="color.value">{{ color.viewValue }}</option>
        </select>
      </mat-form-field>
    </mat-expansion-panel>
  
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Pattern
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>Select Pattern</mat-label>
        <select  matNativeControl [(ngModel)]="selectedPattern">
          <option *ngFor="let pattern of patterns" [value]="pattern.value">{{ pattern.viewValue }}</option>
        </select>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="modal-footer">
    <button type="button" class="btn btn-rose btn btn-primary btn-sm" (click)="onSave()">Save</button>
    <button type="button" class="btn btn-default btn btn-primary btn-sm" (click)="onCancel()">Cancel</button>
  </div>
  